<template>
  <div
    class="uk-flex uk-flex-center uk-flex-middle uk-background-cover"
    uk-height-viewport
    :data-src="backgrounds[Math.floor(Math.random() * backgrounds.length)]"
    uk-img
  >
    <div>
      <div
        class="
          uk-card uk-card-secondary uk-border-rounded uk-width-medium uk-padding
        "
      >
        <div class="uk-text-center">
          <img :src="api + '/storefronts/' + storefrontId + '/logo'" alt />
        </div>

        <div class="uk-text-center uk-margin-top">Reestablecer contraseña</div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider name="email" rules="required|email">
            <input
              class="uk-margin-top uk-input uk-text-center uk-border-rounded"
              type="text"
              v-model="model.email"
              placeholder="correo"
              name="email"
            />
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              @click="forgotPassword"
              :disabled="invalid"
            >
              Solicitar
            </button>
          </div>
        </ValidationObserver>
      </div>

      <div class="uk-text-center uk-margin-top">
        <router-link to="/login" class="uk-link-reset uk-font-underline">
          Iniciar sesión
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
export default {
  name: "ForgotPassword",

  data() {
    return {
      model: {
        email: "",
      },
      backgrounds: [],
    };
  },

  methods: {
    forgotPassword() {
      this.axios.post("/forgot-password", this.model).then(() => {
        UIkit.notification({
          message:
            "Se te ha enviado un correo para el reestablecimiento de contraseña.",
          status: "success",
          pos: "bottom-left",
        });

        this.$router.push("/login");
      });
    },
  },
};
</script>
